import * as React from 'react'
import Layout from '../components/layout'

const CoveredTech = () => {
    return (
        <Layout pageTitle='Covered Technologies and Services' nextBottomSlideBtn="Custom Advisory" nextBottomSlideBtnLink="/CustomAdvisory" id="implementation">
            <div className="fullWidth">
                <div className="listCol listWhiteBg">
                    <ul style={{ animation: `fadeIn .75s ease-in-out forwards`, opacity: 0 }}>
                        <strong>Energy Solutions</strong>
                        <li className="marginTop">Energy audits</li>
                        <li>Outdoor lighting</li>
                        <li>Indoor lighting</li>
                        <li>HVAC repairs and replacement</li>
                        <li>Energy management systems</li>
                        <li>Submetering</li>
                        <li>BAS upgrades &amp; optimization</li>
                        <li>Retro commissioning</li>
                    </ul>
                    <div className="verticalLine" style={{ animation: `fadeIn .75s ease-in-out forwards 1s`, opacity: 0 }}></div>
                    <ul style={{ animation: `fadeIn .75s ease-in-out forwards 1s`, opacity: 0 }}>
                        <strong>Resiliency Solutions</strong>
                        <li className="marginTop">Backup generation</li>
                        <li>On-site solar</li>
                        <li>On-site batteries</li>
                        <li>Microgrids</li>
                        <li>Economic dispatch for distributed energy resources (DERs)</li>
                    </ul>
                    <div className="verticalLine" style={{ animation: `fadeIn .75s ease-in-out forwards 2s`, opacity: 0 }}></div>
                    <ul style={{ animation: `fadeIn .75s ease-in-out forwards 2s`, opacity: 0 }}>
                        <strong>Electrification Solutions</strong>
                        <li className="marginTop">Electric vehicle “make ready” infrastructure</li>
                        <li>Electric vehicle supply equipment (EVSE)</li>
                        <li>EV fleet management &amp; procurement </li>
                        <li>HVAC fuel source retrofit</li>
                    </ul>
                </div>
            </div>
        </Layout>
    )
}

export default CoveredTech